import React from 'react';
import { Link } from 'react-router-dom';
import currencyFormatter from 'currency-formatter';
import { useTranslation } from 'react-i18next';
import { CoinFormatter } from '@wisecryptoanalysis/starbitrex-libs';
import { nonNegativeAmount } from '../redux/apiHelper';

export const PortfolioAccountCloumns = (currencyData, getCoinImg, WalletTransfer, getRateInUsdt) => {
  const { t } = useTranslation();

  const accountColumns = [
    {
      name: `${t('portfolio.coin_name')}`,
      selector: (coin) => currencyData?.find(
        (row) => row._id === coin.currencyId,
      )?.symbol,
      cell: (coin) => {
        const coinSymbol = currencyData?.find(
          (row) => row._id === coin.currencyId,
        )?.symbol;
        return (
          <Link className="portfilio-link" to={`/activity/${coinSymbol}`}>
            <div className="portfolio-coin d-flex align-items-center">
              <img src={getCoinImg(coinSymbol)} alt="btc" className="img-fluid me-2 coin-img p-2" />
              <div>
                <p className="name m-0">
                  {currencyData?.find(
                    (row) => row._id === coin.currencyId,
                  )?.name}
                </p>
                <p className="symbol m-0">{coinSymbol}</p>
              </div>
            </div>
          </Link>
        );
      },
      sortable: true,
    },
    {
      name: `${t('portfolio.spot_wallet_amount')}`,
      selector: (coin) => (coin.amount ? parseFloat(nonNegativeAmount(coin.amount + (coin.credit_amount ?? 0))).toFixed(2) : '-'),
      sortable: true,
    },
    {
      name: `${t('portfolio.24h_change')}`,
      selector: (coin) => (coin.futures_amount ? parseFloat(nonNegativeAmount(coin.futures_amount)).toFixed(2) : '-'),
      sortable: true,
    },
    {
      name: `${t('labels.tranfer_assets')}`,
      cell: () => (
        <WalletTransfer portfolio />
      ),
    },
    {
      name: `${t('labels.in_usd')}`,
      selector: (coin) => {
        const coinSymbol = currencyData?.find((row) => row._id === coin.currencyId)?.symbol;
        const usdtValue = getRateInUsdt(coinSymbol, nonNegativeAmount(CoinFormatter.getFormattedCoinAmount(coin)));

        return (usdtValue && !Number.isNaN(Number(usdtValue))) ? currencyFormatter.format(usdtValue.toFixed(2), { code: 'USD' }) : '-';
      },
      sortable: true,
    },
    {
      name: `${t('portfolio.futures_wallet_amount')}`,
      selector: (coin) => (coin.futures_amount ? parseFloat(nonNegativeAmount(coin.futures_amount)).toFixed(2) : '-'),
      sortable: true,
    },
    {
      name: `${t('labels.available_credits')}`,
      selector: (coin) => (coin.credit_amount ? parseFloat(coin.amount - CoinFormatter.getFormattedCoinAmount(coin)).toFixed(2) : '-'),
      sortable: true,
    },
    {
      name: `${t('labels.total_credits')}`,
      selector: (coin) => (coin.credit_amount ? parseFloat(-coin.credit_amount).toFixed(2) : '-'),
      sortable: true,
      center: true,
    },
    {
      name: `${t('labels.actions')}`,
      cell: (coin) => {
        const coinSymbol = currencyData?.find(
          (row) => row._id === coin.currencyId,
        )?.symbol;
        const fiatCoins = ['USD', 'EUR', 'CAD', 'AUD', 'NZD'];

        if (fiatCoins?.includes(coinSymbol)) {
          return (
            <div className="table-button">
              <Link className="btn trade-button" to={`/exchange/${coinSymbol}`}>
                {t('labels.trade')}
              </Link>
            </div>
          );
        }

        return (
          <div className="table-button">
            <Link className="btn trade-button" to={`/trade/${coinSymbol === 'USDT' ? 'ETH' : coinSymbol}USDT`}>
              {t('labels.trade')}
            </Link>
          </div>
        );
      },

      center: true,
    },
  ];
  return accountColumns;
};
