import { toast } from 'react-toastify';
import {
  GET_ACCOUNT, UPDATE_ACCOUNT, TRANSFER, CLEAR, GET_TOTAL_BALANCE,
  UPDATE_ACCOUNT_FIELD_FROM_SOCKET,
} from './accountTypes';
import { apiHelper } from '../apiHelper';

export const getAccount = (Id) => async (dispatch) => {
  try {
    const res = await apiHelper('get', `/api/account/${Id}`, '');
    if (res?.data) {
      const { data } = res;
      await dispatch({
        type: GET_ACCOUNT,
        payload: data.account,
      });
    }
  } catch (error) {
    // toast.error(error.response.message)
  }
};

export const updateAccount = (id, data) => async (dispatch) => {
  try {
    const res = await apiHelper('put', `/api/account/update-prev-amount/${id}`, data);
    if (res?.data) {
      const { data } = res;
      dispatch({
        type: UPDATE_ACCOUNT,
      });
    }
  } catch (error) {
    // toast.error(error.response.message)
  }
};

export const clearTransfer = () => async (dispatch) => {
  try {
    dispatch({
      type: CLEAR,
    });
  } catch (error) {
    // toast.error(error.response.message)
  }
};

export const getUserTotalBalance = () => async (dispatch) => {
  try {
    const res = await apiHelper('get', '/api/account/balance');

    if (res && res.data && res.data.success) {
      const { totalBalance } = res.data;

      dispatch({
        type: GET_TOTAL_BALANCE,
        payload: totalBalance,
      });
    }
  } catch (error) {
    toast.error(error);
  }
};

export const transferAmounts = (apiData) => async (dispatch) => {
  try {
    const res = await apiHelper('post', '/api/account/transferAmounts', apiData);
    if (res?.data) {
      const { data } = res;
      toast.success('Funds Transferred Successfully');
      dispatch({
        type: TRANSFER,
        payload: data,
      });
      dispatch(getAccount(apiData.userId));
      dispatch(getUserTotalBalance());
      dispatch(clearTransfer());

      return data;
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const updateAccountFieldFromSocket = (fieldData) => ({
  type: UPDATE_ACCOUNT_FIELD_FROM_SOCKET,
  payload: fieldData,
});
